import React from 'react'
import { Location } from '@reach/router'
import { LanguageConsumer } from '../lib/LanguageContext'
import './Error.css'

export default(props) => {

    return (<Location>
      {({ location }) => (
        <LanguageConsumer>
          {({ polyglot }) => (
                <div id='error' class="main">
                  <img id='attention' class='icon' src='/error-attention.svg' alt='error-attention.svg'/>
                  <h1>{ polyglot.t('error.serviceUnavailable') }</h1>
                  <h2>{ polyglot.t('error.tryLater') }</h2> 
                </div>
            )}
          </LanguageConsumer>)}
      </Location>)

}
/***/
