import React from 'react';
import { Urls } from '../index';
import { navigate } from "@reach/router"

const AuthContext = React.createContext({
    loggedIn: false,
    error: false,
    loading: 0,
    logout: () => {},
    refresh: () => {},
});

class AuthProvider extends React.Component {

    state = {
        loggedIn: false,
        error: false,
        loading: 1,
        refresh: () => this.refresh(),
    }

    componentDidMount = () => {
        this.getAuthData()
    }

    refresh = () => {
        if( !localStorage.getItem('last_auth_refresh') || Date.now() - localStorage.getItem('last_auth_refresh') > 1000) {
            this.setState(prevState => ({ loading: prevState.loading + 1 }), this.getAuthData)
        }
    }

    getAuthData = async() => {
        localStorage.setItem('last_auth_refresh', Date.now())
        try {
            const res = await fetch(Urls.authUrl + '/isAuth', { credentials: 'include' })
            let resJson = await res.json()
            if (!resJson.loggedIn) {
                this.setState({loggedIn: false})
                if(resJson.oamSession){
                    window.location = Urls.authUrl + "/logout"; // external redirect to logout
                }
                else {
                    navigate("/");
                }
            } else {
                // update main App() state so  that the Header can track the changes
                this.setState(prevState => 
                ({...prevState, 
                    loggedIn: true,
                    firstname: resJson.firstname,
                    lastname: resJson.lastname,
                    taxid: resJson.taxid
                }));
                
            }
        } catch (e) {
            this.setState({ error: true })
        }
        this.setState(prevState => ({...prevState, loading: prevState.loading - 1 }))
    }

    render = () => (
        <AuthContext.Provider value={this.state}>
            { this.props.children }
        </AuthContext.Provider>
    )
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer, AuthContext }


