
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// require('dotenv').config()

const env = process.env.NODE_ENV === 'github' ? "." + process.env.NODE_ENV : ''
// eslint-disable-next-line import/no-dynamic-require
const Urls = require('./Urls' + env)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export { Urls };



