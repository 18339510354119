import React from 'react';
import './App.css';
import Home from './Home';
import Clients from './Clients';
import Data from './Data';
import Footer from './Footer';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Router, Redirect } from "@reach/router"
import { AuthProvider } from './lib/AuthContext'

library.add(fas)

function App() {

  const NotFound = () => (
    <Redirect to='/' noThrow/>
  )

  return (
    <React.Fragment>
      <AuthProvider>
        <Router>
          <Home path="/"/>
          <Clients path="/consents"/>
          <Data report={true} path="/mydata/report"/>
          <Data report={false} path="/mydata"/>
          <NotFound default />
        </Router>
        <Footer/>
      </AuthProvider>
    </React.Fragment>
  );
}

export default App;
