exports.phrases = {
  nav: {
    home: 'Αρχικη',
    myData: 'Τα Στοιχεία μου',
    banks: 'Άδειες Πρόσβασης',
    login: 'Συνδεση',
    logout: 'Αποσυνδεση'
  },
  home: {
    knowYourCustomer: 'Know Your Customer',
    motto: 'H επικοινωνία του ιδιωτικού και δημόσιου τομέα με κέντρο τον πολίτη'
  },
  login: {
    login: 'Σύνδεση',
    inOrderToLogin: 'Προκειμένου να συνδεθείτε στην εφαρμογή, θα πρέπει να μεταφερθείτε στο',
    TaxisNet: 'TaxisNet',
    byLogginInYouWill: 'Εάν επιθυμείτε να δείτε τα δεδομένα σας και το ιστορικό των εξουσιοδοτήσεων αρκεί να συνδεθείτε στο',
    mykyc: 'MyKYC'
  },
  redirect: {
    youAreBeingRedirected: 'Μεταφέρεστε στον ιστότοπο',
    ifYouAreNotRedirected: 'Εάν δεν μεταφερθείτε αυτόματα',
    clickHere: 'πατήστε εδώ',
  },
  data: {
    myCurrentData: 'Τα στοιχεία μου την τρέχουσα χρονική στιγμή',
    lastUpdate: 'Τελευταία Επικαιροποίηση',
    refresh: 'Ανανέωση',
    noData: 'Δεν βρέθηκαν στοιχεία',
    noteFaq: 'Σε περίπτωση εντοπισμού απόκλισης στα παραπάνω δεδομένα συμβουλευτείτε τις',
    noConnectionAvailable: 'Η σύνδεση με τον υπεύθυνο φορέα δεν ήταν εφικτή.',
    insufficientData: 'Δεν βρέθηκαν επαρκή στοιχεία.',
    identity: {
      type: 'Τύπος Ταυτότητας',
      title: 'Στοιχεία Ταυτοποιητικού Εγγράφου',
      idnumber: 'Αριθμός Ταυτοποιητικού Εγγράφου',
      issueDate: 'Ημερομηνία Έκδοσης',
      expirationDate: 'Ημερομηνία Λήξης',
      issueInstitution: 'Αρχή Έκδοσης',
      identificationCard:'Αστυνομική Ταυτότητα',
      name: 'Όνομα',
      nameLatin: 'Όνομα (Λατινικά)',
      surname: 'Επώνυμο',
      surnameLatin: 'Επώνυμο (Λατινικά)',
      fatherName: 'Όνομα Πατρός',
      fatherNameLatin: 'Όνομα Πατρός (Λατινικά)',
      motherName: 'Όνομα Μητρός',
      motherNameLatin: 'Όνομα Μητρός (Λατινικά)',
      birthDate: 'Ημερομηνία Γέννησης',
      birthPlace: 'Τόπος Γέννησης',
      gender: 'Φύλο',
      description: 'Περιγραφή',
      id: 'Αναγνωριστικό',
      previousIdNumber: 'Αριθμός Προηγούμενου Εγγράφου',
      lastUpdateDate: 'Ημερομηνία Τελευταίας Ανανέωσης',
      isActive: 'Ενεργή',
      female: 'Θηλυ',
      male: 'Αρρεν',
      image: 'Φωτογραφία',
      message: 'Μήνυμα',
      municipality: 'Δήμος',
      populationRegistryNo: 'Αριθμός Δημοτολογίου'
    },
    contactInfo: {
      title: 'Στοιχεία Επικοινωνίας',
      address: 'Διεύθυνση Διαμονής',
      contactAddress: 'Διεύθυνση Επικοινωνίας',
      zipCode: 'ΤΚ',
      telephone: 'Τηλέφωνο',
      email: 'Ηλ. Ταχυδρομική Διεύθυνση (Ε-mail)',
      mobile: 'Κινητό Τηλέφωνο',
      message: 'Μήνυμα',
      errorTextBeforeUrl: 'Επικαιροποιήστε τα στοιχεία επικοινωνίας σας στο ',
      errorUrlText: 'Εθνικό Μητρώο Επικοινωνίας (Ε.Μ.Επ.) ',
      errorTextAfterUrl: 'και επαναλάβετε τη διαδικασία'

    },
    income: {
      taxid: 'Αριθμός Φορολογικού Μητρώου',
      title: 'Στοιχεία Εισοδήματος',
      tin: 'Αριθμός Φορολογικού Μητρώου',
      category: 'Κατηγορία',
      grade: 'Κλίμακα',
      incomeClassLegend: '* Κλίμακες Εισοδημάτων',
      status: 'status',
      checkAfm: 'ΑΦΜ',
      refYear: 'Έτος Χρήσης (Ημερομηνία Πράξης)',
      whoIs: 'whoIs',
      rentalIncome: 'Ακίνητη Περιουσία',
      declId: 'Αριθμός Δήλωσης',
      releaseDate: 'Ημερομηνία Πράξης',
      investmentIncome: 'Μερίσματα - Τόκοι - Δικαιώματα',
      farmingIncome: 'Αγροτική Δραστηριότητα',
      businessIncome: 'Επιχειρηματική Δραστηριότητα',
      wagesPensionsIncome: 'Μισθωτή Εργασία - Συντάξεις',
      capitalTransferValue: 'Υπεραξία Μεταβίβασης Κεφαλαίου',
      maritimeIncome: 'Ναυτικό Εισόδημα',
      damageBusinessCurr: 'Επιχειρηματική Δραστηριότητα (Ζημιά)',
      damageFarmingCurr: 'Αγροτική Δραστηριότητα (Ζημιά)',
      addedCostDiffs: 'Προστιθέμενη Διαφορά Δαπανών',
      taxableAmounts: 'Αυτοτελώς Φορολογούμενα Ποσά',
      grossBusiness: 'Ακαθάριστα Έσοδα',
      grossFarming: 'Ακαθάριστα Έσοδα',
      unemploymentBenefits: 'Επίδομα Ανεργίας',
      requestTimestamp: 'Ημερομηνία Άντλησης',
      incomeData: 'Πληροφορίες Εισοδήματος',
      noClearedStatementFound: 'Δεν βρέθηκε εκκαθαρισμένη δήλωση',
      errorOccured: 'Προέκυψε κάποιο σφάλμα κατά την αναζήτηση'
    },
    professionalActivity: {
      privateEmployeeInfo: 'Μισθωτός Ιδιωτικού Δικαίου',
      publicEmployeeInfo: 'Μισθωτός Δημοσίου Τομέα',
      selfEmployedInfo: 'Αυτοαπασχολούμενος',
      employer: 'Εργοδότης',
      title: 'Στοιχεία Επαγγελματικής Δραστηριότητας',
      taxid: 'Αριθμός Φορολογικού Μητρώου',
      activity: 'Δραστηριότητα (Κ.Α.Δ.)',
      brandName: 'Επωνυμία',
      location: 'Έδρα',
      employee: 'Απασχολούμενος Μισθωτός',
      employementRegime: 'Καθεστώς Απασχόλησης',
      status: 'Κωδικός κατάστασης.',
      checkAfm: 'ΑΦΜ αναζήτησης',
      names: 'Επωνυμία',
      firmBase: 'Έδρα',
      startDate: 'Ημερομηνία έναρξης επαγγελματικής δραστηριότητας',
      zipCode: 'ΤΚ',
      parDescription: 'Περιοχή/πόλη διεύθυνσης',
      individualOrNot: 'individualOrNot',
      isFirm: 'isFirm',
      isActive: 'isActive',
      activities: 'Δραστηριότητες',
      firmActTab: 'Πίνακας/λίστα με τις εγγραφές δραστηριοτήτων',
      registryData: 'Στοιχεία Φορολογικού Μητρώοου',
      firmActCode: 'Κωδικός Δραστηριότητας',
      firmActDescr: 'Περιγραφή Δραστηριότητας',
      firmActKind: 'Κωδικός Είδους Δραστηριότητας',
      firmActKindDescr: 'Περιγραφή Είδους Δραστηριότητας',
      activityDescr: 'Περιγραφή Δραστηριότητας',
      requestTimestamp: 'Ημερομηνία Άντλησης',
      deactivated: 'Ο Α.Φ.Μ είναι απενεργοποιημένος',
      notFoundinTheRegistry: 'Ο Α.Φ.Μ δεν υπάρχει στο μητρώο',
      primaryActivity: 'Κύρια Δραστηριότητα (Κ.Α.Δ.)',
      secondaryActivities: 'Δευτερεύουσες Δραστηριότητες (Κ.Α.Δ.)',
      merTaxId: 'ΑΦΜ Εργοδότη',
      merName: 'Επωνυμία',
      jobAddress: 'Έδρα',
      website: 'Ιστοσελίδα',
      municipality: 'Δημου',
      specialization: 'Ειδικότητα',
      regime: 'Καθεστώς Απασχόλησης',
      full: 'Πληρης',
      partial: 'Μερικη',
      shiftwork: 'Εκ Περιτροπης',
      branch: 'Παράρτημα',
      motherCompany: 'Μητρική Εταιρεία',
      employeeActivity: 'Απασχόληση Εργαζομένου',
      pensionerInfo: 'Στοιχεία Συνταξιούχου',
      isPensioner: 'Συνταξιούχος',
      iekStudentInfo: 'Στοιχεία φοίτησης σε Δημόσιο ή Ιδιωτικό ΙΕΚ',
      isIekStudent: 'Φοίτηση',
      academicInfo: 'Στοιχεία Ακαδημαϊκής Ιδιότητας',
      academicId: 'Φοιτητική Ιδιότητα',
      image: 'Φωτογραφία',
      yes: 'Ναι',
      no: 'Οχι',
      public: {
        primary: "Κύρια Σχέση Εργασίας",
        vat: "ΑΦΜ Φορέα",
        name: "Όνομα Φορέα",
        address: "Έδρα",
        type: "Εργασιακή Σχέση",
        category: "Κατηγορία Προσωπικού",
        sector: "Κλάδος",
        specialty: "Ειδικότητα"
      },
      unemploymentInfo: "Στοιχεία Ανεργίας",
      repidot: "Επιδότηση Τακτικής Ανεργίας",
      active: "Ενεργη",
      inactive: "Ανενεργη",
      rmonthsepidot: "Ημέρες Επιδότησης από την",
    },
    taxidInfo: {
      title: "Στοιχεία Δ.Ο.Υ",
      registryInfo: "Στοιχεία Δ.Ο.Υ",
      tin: "ΑΦΜ",
      doy: "Κωδικός Δ.Ο.Υ",
      doyDescription: "Περιγραφή Δ.Ο.Υ"
    },
    demographics: {
      title: 'Δημογραφικά Στοιχεία',
      birthCountryName: 'Χώρα Γέννησης',
      mainNationalityName: 'Υπηκοότητα',
    },
    familyStatus: {
      title: 'Στοιχεία Οικογενειακής Κατάστασης',
      firstName: 'Όνομα',
      surname: 'Επώνυμο',
      secondSurname: 'Δεύτερο επώνυμο',
      fathersFirstName: 'Πατρώνυμο',
      maritalStatusDesc: 'Οικογενειακή Κατάσταση',
      maritalStatusDescSyz: 'Οικογενειακή Κατάσταση Συζύγου'
    },
    sources: {
      'elas': {
        short: 'ΕΛ.ΑΣ',
        long: 'Ταυτότητες Ελλήνων Πολιτών'
      },
      'ggps': {
        short: 'ΓΓΠΣ&ΨΔ',
        long: 'Εθνικό Μητρώο Επικοινωνίας (Ε.Μ.Επ.)'
      },
      'aadeIncome': {
        short: 'ΑΑΔΕ',
        long: 'Φορολογία Εισοδήματος'
      },
     'aadeSelfEmployed': {
        short: 'ΑΑΔΕ',
        long: 'Μητρώο Αυτοαπασχολούμενων'
      },
      'ergani': {
        short: 'Υπουργείο Εργασίας και Κοινωνικής Ασφάλισης',
        long: 'Μητρώο Μισθωτών Ιδιωτικού Δικαίου ΕΡΓΑΝΗ'
      },
      'publicSector': {
        short: 'ΑΠΟΓΡΑΦΗ - Υπουργείο Εσωτερικών',
        long: 'Μητρώο Ανθρώπινου Δυναμικού Ελληνικού Δημοσίου'
      },
      'aadeTaxidInfo': {
        short: 'ΑΑΔΕ',
        long: 'Στοιχεία Δ.Ο.Υ'
      },
      'army': {
        short: 'υ.εθ.α.',
        long: 'Ταυτότητες Ενστόλων'
      },
      'fire': {
        short: '',
        long: 'Υπηρεσιακά Δελτία Ταυτότητας Πυροσβεστικού Σώματος'
      },
      'hcg': {
        short: '',
        long: 'Υπηρεσιακά Δελτία Ταυτότητας Λιμενικού Σώματος'
      },
      'elasOff': {
        short: 'ΕΛ.ΑΣ',
        long: 'Υπηρεσιακά Δελτία Ταυτότητας Αστυνομικού Προσωπικού'
      },
      'elasPassport': {
        short: 'ΕΛ.ΑΣ',
        long: 'Ελληνικά Διαβατήρια'
      },
      'aadefamilyStatus': {
        short: 'ΑΑΔΕ',
        long: 'Στοιχεία Οικογενειακής Κατάστασης'
      },
      'emigPermit': {
        short: 'Υ.Μ.Α',
        long: 'Άδειες Διαμονής'
      },
      'emigPassport': {
        short: 'Υ.Μ.Α',
        long: 'Ταξιδιωτικά Έγγραφα Μεταναστών'
      },
      'dypa': {
        short: 'Δ.ΥΠ.Α',
        long: 'Στοιχεία Ανεργίας'
      },
      'efka': {
        short: 'ΕΦΚΑ',
        long: 'Στοιχεία Συνταξιούχων'
      },
      'edyte': {
        short: 'ΕΔΥΤΕ',
        long: 'Στοιχεία Ακαδημαϊκής Ιδιότητας',
      },
      'ypes': {
        short: 'Υπουργείο Εσωτερικών',
        long: 'Μητρώο Πολιτών'
      },
      'minedu': {
        short: 'Υ.ΠΑΙ.Θ.Α',
        long: 'Στοιχεία φοίτησης σε Δημόσιο ή Ιδιωτικό ΙΕΚ'
      },
      'other': {
        short: '',
        long: '',
      }
    }
  },
  clients: {
    banks: 'Φορείς',
    clientCategories: 'Κατηγορίες Φορέων',
    accessToData: 'Άδειες πρόσβασης στα δεδομένα σας',
    noAccessToData: 'Δεν υπάρχουν άδειες πρόσβασης στα δεδομένα σας που να πληρούν τα κριτήρια αναζήτησης',
    historyOfAccessToData: 'Ιστορικό Πρόσβασης',
    expiredAccessToData: 'άδεια πρόσβασης που έχει λήξει',
    revoke: 'Ανάκληση',
    noExpiredAccessToData: 'Δεν υπάρχουν ανενεργές άδειες πρόσβασης που να πληρούν τα κριτήρια αναζήτησης',
    revoked: 'Ανακλήθηκε',
    expired: 'Έληξε',
    orgs: {
      credit: 'Πιστωτικά Ιδρύματα',
      depositories: 'Ταμείο Παρακαταθηκών και Δανείων',
      insurance: 'Ασφαλιστικοί Οργανισμοί',
      payments: 'Πληρωμές, Ηλεκτρονικό Χρήμα και Συνάλλαγμα',
      capital: 'Επενδύσεις και Κεφάλαια',
      requirements: 'Διαχείριση Απαιτήσεων',
      misc: 'Λοιποί Οργανισμοί'
    },
    scopes: 'Στοιχεία',
    scopeNames: {
      identity: 'Ταυτότητας',
      income: 'Εισοδήματος',
      contactInfo: 'Επικοινωνίας',
      professionalActivity: 'Επαγγελματικής Δραστηριότητας',
      taxidInfo: 'Δ.Ο.Υ',
      familyStatus: 'Οικογενειακής Κατάστασης',
      demographics: 'Δημογραφικά'
    }
  },
  info: {
    aboutUs: 'Σχετικά με το eGov KYC',
    termsOfUse: 'Όροι Χρήσης',
    privacyPolicy: 'Ιδιωτικό Απόρρητο',
    faq: 'Συχνές Ερωτήσεις'
  },
  error: {
    oops: 'Ωχ',
    somethingWentWrong: 'Κάτι πήγε στραβά',
    returnToHomepage: 'Επιστροφή στην αρχική',
    serviceUnavailable: 'H υπηρεσία δεν είναι διαθέσιμη',
    tryLater:'Παρακαλούμε δοκιμάστε αργότερα',
    authFail:'O χρήστης δεν πληροί τα κριτήρια για χρήση της υπηρεσίας KYC',
    tryAgain:'Δοκιμάστε άλλο χρήστη'
//να χρησιμοποιήσει 
  },
  taxisNet: 'TaxisNet',
  titles: {
    main: 'eGov KYC',
    login: '- Σύνδεση',
    clients: ' - Φορείς',
    data: ' - Δεδομένα'
  }
}
