import React from 'react';
import Login from './Login'
import { AuthConsumer } from "./lib/AuthContext"
import { Redirect } from "@reach/router"
import { LanguageConsumer } from './lib/LanguageContext'
import MetaTags from 'react-meta-tags'
import Header from './Header'

export default (props) => {

  const next = props.location && props.location.state && props.location.state.next ? props.location.state.next : ''
  const title = 'eGov KYC - Αρχική'

  return (
    <React.Fragment>
      <Header disableNav={true} disableLogout={true}/>
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <AuthConsumer>
        {({ loggedIn, loading }) => (
            <LanguageConsumer>
              {({ polyglot }) => (
                <React.Fragment>
                  {loggedIn && <Redirect from="/" to="mydata" noThrow />}
                  {!loggedIn && (
                    <React.Fragment>
                      <div id='Home'>
                        <div className='main container'>
                          <h1 className='heading'>Ας συστηθούμε</h1>
                          <p>Η υπηρεσία <strong>"Συστηθείτε" (eGov KYC- Know Your Customer)</strong> προσφέρει μια ψηφιακή εναλλακτική στην προσκόμιση εγγράφων του δημοσίου, για τη βεβαίωση στοιχείων του πολίτη σε φορείς του ιδιωτικού τομέα εφόσον απαιτείται από τη νομοθεσία.
                          </p>
                          <p>Τα στοιχεία αντλούνται από τα πρωτογενή πληροφοριακά συστήματα του Δημοσίου χωρίς να αποθηκεύονται στο eGov KYC και η διάθεσή τους προϋποθέτει κάθε φορά τη ρητή συγκατάθεση του πολίτη. <br/></p>
                          <Login next={next} />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>)}
            </LanguageConsumer>
        )}
      </AuthConsumer>
    </React.Fragment>)
}