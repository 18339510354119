import React, { useState, useEffect } from 'react'
import CollapsePill from './components/CollapsePill'
import Authentication from './lib/Authentication'
import { LanguageConsumer } from './lib/LanguageContext'
import { Redirect } from "@reach/router"
import Info from './components/Info'
import './Clients.css'
import { Urls } from './index'
import moment from 'moment'
import shortid from 'shortid'
import Error from './components/Error'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-free-solid'
import { normalizeString, formatScopes} from './lib/util'
import MetaTags from 'react-meta-tags'
import Header from './Header'


const CheckBox = (props) => (
    <span>
        <label className={`filterbox ${props.disabled ? 'disabled' : ''}`}>
            <input type='checkbox' className='filtercheck' onChange={() => props.handleChange(props.category)} onClick={(e) => e.stopPropagation()} checked={props.status} disabled={props.disabled}/>
            <span className='checkbox-label'>{props.label}</span>
        </label>
    </span>
)

const ClientsContent = (props) => {
    const [clients, setClients] = useState([])
    const [visibleClients, setVisibleClients] = useState([])
    const [fullFilters, setFullFilters] = useState([]); //the organisations objects, containing id, name and display_name
    const [categoriesFilter, setCategoriesFilter] = useState({});       //initially empty
    const [filtersAvailability, setFiltersAvailability] = useState({}); //
    const [filtersVisible, setFiltersVisible] = useState(false)
    const [error, setError] = useState(false)
    const [httpError, setHttpError] = useState('');
    const [loading, setLoading]  = useState(true)
    const [searchFilter, setSearchFilter] = useState('')
    
    const [renewClients, setRenewClients] = useState(false)
    const [organisationsFetched, setOrganisationsFetched] = useState(false)
    const title = 'eGov KYC - Άδειες Πρόσβασης'

    const toggleCategory = (category) => setCategoriesFilter({ ...categoriesFilter, [category]: !categoriesFilter[category] })

    const fetchClients = async () => {
        // Fetch clients
        const clients = await fetch(Urls.authUrl + '/api-clients', {credentials: 'include'})
        if(clients.status === 403) {
            setLoading(false)
            setHttpError('403')
            return setError(true)
        }
        const clientsJson = await clients.json()
        if (clientsJson.error) {
            setLoading(false)
            return setError(true)
        }
        
        const sortedClients = clientsJson.clients.map(client => (
            {...client, tokens: client.tokens.sort((A, B) => A.time_issued - B.time_issued)}))

        //only fetch organisations once on mount
        if(!organisationsFetched) {
            //get the organisations objects
            const organisations = clientsJson.organisations;
            const organisationNames = {};
            //construct 'categoriesFilter' and 'filterAvailability' using only the 'name' field of each org in the format of:
            //   credit: true/false
            //   depositories: true/false
            //   ..
            for (var i = 0; i<organisations.length; i++) {
                organisationNames[organisations[i]['name']] = true;
            }
            setCategoriesFilter(organisationNames);
            Object.keys(organisationNames).forEach(function(key){ organisationNames[key] = false });
            setFiltersAvailability(organisationNames);
            setFullFilters(organisations);
            setOrganisationsFetched(true)
        }
        
        setLoading(false)
        setClients(sortedClients)
        setVisibleClients(sortedClients)
    }

    useEffect(() => {
        // On mounted
        setRenewClients(true)
    }, [])

    useEffect(() => {
        if(renewClients === true) {
            setRenewClients(false)
            fetchClients()
        }
    }, [renewClients])

    useEffect(() => {
        initFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clients])

    const refreshData = () => {
        setLoading(true)
    }

    let filterRef = React.createRef();

    const initFilters = () => {
        let newFiltersAvailability = filtersAvailability
        let newCategoriesFilter = categoriesFilter
        Object.keys(newFiltersAvailability).map(key => {
            let found = clients.find(client => client.org_name === key)
            newFiltersAvailability[key] = found ? true : false
            newCategoriesFilter[key] = found ? true : false
            return newFiltersAvailability;
        })
        setFiltersAvailability(newFiltersAvailability)
        setCategoriesFilter(newCategoriesFilter)
    }

    const handleOpenFilters = (e) => {
        setFiltersVisible(!filtersVisible)
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }

    const handleCloseFilters = (e) => {
        setFiltersVisible(false)
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }

    const handleSearchChange = (e) => {
        let value = e.target.value
        setSearchFilter(value)
        let matchingClients
        setSearchFilter(value)
        let filter = normalizeString(value) ? new RegExp("(^|\\s)" + normalizeString(value), "i") : null
        if (!filter) {
            matchingClients = clients
        } else {
            matchingClients = clients.filter(client => filter.test(normalizeString(client.display_name)))
        }
        setVisibleClients(matchingClients)
    }

    const handleSearchReset = (e) => {
        setSearchFilter('')
        setVisibleClients(clients)
    }


    return (
        <React.Fragment>
              <Header/>
            <MetaTags>
                <title>{title}</title>
            </MetaTags>
            <LanguageConsumer>
                {({ polyglot }) => (
                    !error ? (
                        <React.Fragment>
                            <div id="Clients">
                                <div className='main'>
                                    <div className='header'>
                                        <h1>{polyglot.t('clients.accessToData')}</h1>
                                        <span className='pull-right'>
                                            <span id='last-updated'>
                                                {loading && (<span className='loading value'></span>)}
                                            </span>
                                        </span>
                                    </div>
                                    { !loading ? (
                                        <React.Fragment>
                                    <div className='Filters Filters-mobile'>
                                        <button className={`filterbutton ${filtersVisible? 'open' : ''}`} onClick={handleOpenFilters}><FontAwesomeIcon icon='filter'/> Φίλτρα </button>
                                        <div className={`filterselect ${filtersVisible ? '' : 'hidden'}`} ref={filterRef}>
                                            <div className='Header'>
                                                {polyglot.t('clients.clientCategories')}
                                            </div>
                                            <div className='Footer'>
                                                <div className='action-area'>
                                                    <button className='set' onClick={handleCloseFilters}><FontAwesomeIcon icon='times' className="fa-lg" /></button>
                                                </div>
                                            </div>
                                            <div className='Filterlist'>
                                            { 
                                                fullFilters.map(x => (
                                                    <CheckBox key={x.id} label={x.display_name} status={categoriesFilter[x.name]} handleChange={() => toggleCategory(x.name)} disabled={!filtersAvailability[x.name]}/>
                                                )) 
                                            }
                                            </div>
                                        </div>
                                    </div> 
                                    <div className='Filters'>
                                        <div className='Header'>
                                            Κατηγορίες Φορέων
                                        </div>
                                        <div className='Filterlist'>
                                            { 
                                                fullFilters.map(x => (
                                                    <CheckBox key={x.id} label={x.display_name} status={categoriesFilter[x.name]} handleChange={() => toggleCategory(x.name)} disabled={!filtersAvailability[x.name]}/>
                                                )) 
                                            }
                                        </div>
                                    </div> 
                                    <div className='clients-area'> 
                                        <div className='search-wrapper'>
                                            <div className='search'>
                                                <input type='text' placeholder='Αναζήτηση με όνομα φορέα...' value={searchFilter} onChange={handleSearchChange}/>
                                                <span className='controls'>
                                                    {searchFilter !== '' && (<button className='close' onClick={handleSearchReset}><FontAwesomeIcon icon='times'/>{' '}</button>)}
                                                    <FontAwesomeIcon icon='search'/>
                                                </span>
                                            </div>
                                        </div>
                                        {visibleClients.filter(client => categoriesFilter[client.org_name]).length === 0 && <Info>{polyglot.t('clients.noAccessToData')}</Info> }
                                        <div className='pill-area'>
                                                {visibleClients.filter(client => categoriesFilter[client.org_name]).map(client => (
                                                <CollapsePill key={shortid.generate()}
                                                    header={client.display_name} 
                                                    org={fullFilters.find(org => org.name === client.org_name).display_name || ""}
                                                    lastDate={client.tokens[client.tokens.length-1].time_issued}
                                                    polyglot={polyglot}
                                                    lastScope={client.tokens[client.tokens.length-1].scopes}
                                                    >
                                                   
                                                    <CollapsePill.DataTable>
                                                        <div>
                                                            <div className='previousAuthorizations'>
                                                                <h4>Προηγούμενες Άδειες Πρόσβασης</h4>
                                                                { client.tokens.slice(0, client.tokens.length-1).reverse().map(token =>
                                                                    (<span key={shortid.generate()} className='pastDate'>{moment.unix(token.time_issued).format("MMMM, DD/MM/YYYY hh:mm A")}
                                                                        <span className='scopes'>
                                                                            {polyglot.t('clients.scopes')}:{' '}
                                                                            {formatScopes(token.scopes.split(" ").map(scope => polyglot.t('clients.scopeNames.' + scope)))}
                                                                        </span>
                                                                    </span>))
                                                                }
                                                            </div>
                                                        </div>
                                                    </CollapsePill.DataTable>
                                                </CollapsePill>
                                            ))}
                                        </div>
                                    </div>
                                </React.Fragment>) : (
                                    <React.Fragment>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        </React.Fragment>) 
                    : 
                    (    
                        <React.Fragment>
                            {/* if the error is caused because the session has expired, redirect to the home page */}
                            { httpError === '403'  && (
                                    <Redirect to='/' noThrow/>
                                )
                            }
                            <React.Fragment>
                                <Error refresh={refreshData}/>
                            </React.Fragment>
                        </React.Fragment>
                    )
                )}
            </LanguageConsumer>
        </React.Fragment>
    )
}

export default (props) => (
    <Authentication next='consents'>
       <ClientsContent />
    </Authentication>
)
