import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-free-solid'
import { LanguageConsumer } from './lib/LanguageContext'
import { Redirect } from "@reach/router"
import { DataCategory, Section, SingleValue, ImageValue, DateValue, MoneyValue, MapValue, ErrorValue, SeparatedValue } from './components/DataCategory'
import Authentication from './lib/Authentication'
import { formatMobileNumber } from './lib/util'
import Error from './components/Error'
import moment from 'moment'
import 'moment/locale/el'
import './Data.css'
import { Urls } from './index';
import MetaTags from 'react-meta-tags';
import Header from './Header'

const IncomeCategory = (props) => {
  // Calculate sum of incomes, if no income of this type exists, dont print split
  const sum = props.children.map(item => parseInt(item.props.value)).reduce((sum, x) => sum += x)
  if (props.children) {
    return (
      <React.Fragment>
        {props.children}
        {sum !== 0 && <hr />}
      </React.Fragment>
    )
  } else {
    return ''
  }
}

const DataContent = (props) => {
  const [lastUpdateTime, setLastUpdateTime] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [formattedLastUpdateTime, setFormattedLastUpdateTime] = useState('')
  const [userData, setUserData] = useState({})
  const [loading, setLoading] = useState(true)
  const [contactInfoPartialData, setContactInfoPartialData] = useState(true)
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState('');
  const [httpError, setHttpError] = useState('');
  const title = 'eGov KYC - Τα Δεδομένα μου'
  const today = new Date();
  const dateOneYearAgo = moment(today).subtract(365, 'days').format('YYYY-MM-DD');

  const updateFormattedTime = () => {
    if (!loading) {
      let formattedUpdateTime = moment(lastUpdateTime).fromNow()
      setFormattedLastUpdateTime(formattedUpdateTime)
    }
  }


  const fetchUserData = async () => {
    let authUrl = Urls.authUrl + '/api-mydata'
    if (props.report) {
      authUrl += '?report=true'
    }
    let res = await fetch(authUrl, { credentials: 'include' });
    if (res.status === 403) {
      setHttpError('403')
      setLoading(false)
      return setError(true)
    }
    else if (res.status !== 200) {
      setHttpError(res.status + '')
      setLoading(false)
      return setError(true)
    }
    const resJson = await res.json()
    if (resJson.result.status.substr(0, 2) !== '12') {
      setLoading(false)
      setErrorCode(resJson.result.status)
      return setError(true)
    }
    let newUserData = resJson.data
    const newUpdateTime = new Date().toString()
    let formattedTime = moment(newUpdateTime)
    formattedTime.locale('el')
    const formattedNewUpdateTime = formattedTime.fromNow()
    setUserData(newUserData)
    setLastUpdateTime(newUpdateTime)
    setFormattedLastUpdateTime(formattedNewUpdateTime);
    setLoading(false)
    if (newUserData['contactInfo']) {
      setContactInfoPartialData(contactInfoPartialCheck(newUserData['contactInfo']))
    }
    return newUserData
  }

  useEffect(() => {
    let timer = null;
    let id = null;
    if (loading === true) {
      timer = setTimeout(() => {
        fetchUserData()
      }, 1000)
    }
    else{
      id = setInterval(updateFormattedTime, 12000);
    }
    return () => {
      clearInterval(id);
      clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const refreshData = () => {
    setLoading(true)
  }

  const printActivity = (activity) => {
    if (activity) {
      return (`${activity.activityDescr} (${activity.activityCode})`)      
    } else {
      return ""
    }
  }

  const printSecondary = (activities) => {
    if (activities.length === 0) {
      return '-';
    }

    return activities.map(activity => (
      <ul className='activity-list'>
        <li>{printActivity(activity)}</li>
      </ul>
    ))
  }


  function correctAddressData(address) {
    if(!address) {
      return false
    }
    if (address.lines.length === 0) {
      return false
    }

    return true
  }

  function contactInfoPartialCheck({data, result}) {
    if (result.status.substr(0, 2) !== "12") {
      return false;
    }
    if (Object.keys(data).length === 0) {
      return true;
    }
    return !(data.telephone && data.mobile && correctAddressData(data.address) && correctAddressData(data.contactAddress) && data.email)
  }

  //Categories
  const identityCategory = userData['identity']
  const familyStatusCategory = userData['familyStatus']
  const incomeCategory = userData['income']
  const contactInfoCategory = userData['contactInfo']
  const professionalActivityCategory = userData['professionalActivity']
  const taxidInfoCategory = userData['taxidInfo']
  const demographicsCategory = userData['demographics']

  //Sections
  const identitySection = identityCategory ? identityCategory.data.grcBo || identityCategory.data.grcAo || identityCategory.data.grcHo || identityCategory.data.grcBs || identityCategory.data.grc : false
  const familyStatusSection = familyStatusCategory ? familyStatusCategory : false
  const incomeSection = incomeCategory ? incomeCategory : false
  const contactInfoSection = contactInfoCategory ? contactInfoCategory : false
  const taxidInfoSection = taxidInfoCategory ? taxidInfoCategory : false
  const demographicsSection = demographicsCategory ? demographicsCategory : false
  const privateEmployeeInfoSection = professionalActivityCategory ? professionalActivityCategory.data.privateEmployeeInfo : false
  const publicEmployeeInfoSection = professionalActivityCategory ? professionalActivityCategory.data.publicEmployeeInfo : false
  const selfEmployedInfoSection = professionalActivityCategory ? professionalActivityCategory.data.selfEmployedInfo : false
  const unemploymentInfoSection = professionalActivityCategory ? professionalActivityCategory.data.unemploymentInfo : false
  const pensionerInfoSection = professionalActivityCategory ? professionalActivityCategory.data.pensionerInfo : false
  const academicInfoSection = professionalActivityCategory ? professionalActivityCategory.data.academicInfo : false
  const iekStudentInfoSection = professionalActivityCategory ? professionalActivityCategory.data.iekStudentInfo : false

  const PartialContactUI = () => (
    <React.Fragment>
      <LanguageConsumer>
        {({ polyglot }) => (
          <p> {polyglot.t(`data.contactInfo.errorTextBeforeUrl`)} <a href={Urls.contactErrorUrl} target="_blank" rel="noopener noreferrer"> {polyglot.t(`data.contactInfo.errorUrlText`)} </a> {polyglot.t(`data.contactInfo.errorTextAfterUrl`)}</p>
        )}
      </LanguageConsumer>
    </React.Fragment>
  )

  const identityMap = {
    'GRC-BO': 'elas',
    'GRC-BS': 'elasOff',
    'GRC-AO': 'elasPassport',
    'GRC-HO': 'emigPermit',
    'GRC-JO': 'elasPassport',
    'HCG': 'hcg',
    'FIRESERVICES': 'fire',
    'ARMY': 'army',
    'OTHER': 'other'
  }

  return (
    <React.Fragment>
      <Header disableNav={props.report || error} disableLogout={props.report}/>

      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <LanguageConsumer>
       
        {({ polyglot }) => (
          !error ? (
            <React.Fragment>
              <div className='main' id='my-data'>
                <div className='header'>
                  <h1>{polyglot.t('data.myCurrentData')}</h1>
                  <span className='pull-right'>
                    <span id='last-updated'>
                      {/* <span className='key'>{polyglot.t('data.lastUpdate')}</span>
                      {!loading ? (
                        <span className='value'>{formattedLastUpdateTime}</span>
                      ) : (
                          <span className='loading value'></span>
                        )} */}
                      {loading && (<span className='loading value'></span>)}
                    </span>
                    <button id='refresh' style={{display:"none"}} onClick={refreshData}>{polyglot.t('data.refresh')}</button>
                  </span>
                </div>
                <div className='data-container'>
                  { !loading && (
                    Object.keys(userData).length ? (
                      <React.Fragment>
                        {identitySection && (
                          <DataCategory display={identitySection} category='identity' icon={(identityCategory && identityCategory.icon) || ''}>
                            <Section singleSection={true} status={identitySection.result.status}
                              title={polyglot.t(`data.identity.identificationCard`)} source={`${identitySection.data.documentInfo ? identityMap[identitySection.data.documentInfo.category] : 'other'}`}>
                              {identitySection.data.documentInfo && (
                                <React.Fragment>
                                  <SingleValue id={polyglot.t(`data.identity.idnumber`)} value={identitySection.data.documentInfo.id} />
                                  <DateValue id={polyglot.t(`data.identity.issueDate`)} value={identitySection.data.documentInfo.issueDate} />
                                  <DateValue id={polyglot.t(`data.identity.expirationDate`)} value={identitySection.data.documentInfo.expirationDate} />
                                  <SingleValue id={polyglot.t(`data.identity.issueInstitution`)} value={identitySection.data.documentInfo.issuerName} />
                                  <SingleValue id={polyglot.t(`data.identity.previousIdNumber`)} value={identitySection.data.documentInfo.previousId} />
                                </React.Fragment>
                              )}
                              {identitySection.data.userInfo && (
                                <React.Fragment>
                                  <SingleValue id={polyglot.t(`data.identity.name`)} value={identitySection.data.userInfo.name} />
                                  <SingleValue id={polyglot.t(`data.identity.nameLatin`)} value={identitySection.data.userInfo.nameLatin} />
                                  <SingleValue id={polyglot.t(`data.identity.surname`)} value={identitySection.data.userInfo.surname} />
                                  <SingleValue id={polyglot.t(`data.identity.surnameLatin`)} value={identitySection.data.userInfo.surnameLatin} />
                                  <SingleValue id={polyglot.t(`data.identity.fatherName`)} value={identitySection.data.userInfo.fatherName} />
                                  <SingleValue id={polyglot.t(`data.identity.fatherNameLatin`)} value={identitySection.data.userInfo.fatherNameLatin} />
                                  <SingleValue id={polyglot.t(`data.identity.motherName`)} value={identitySection.data.userInfo.motherName} />
                                  <SingleValue id={polyglot.t(`data.identity.motherNameLatin`)} value={identitySection.data.userInfo.motherNameLatin} />
                                  <DateValue id={polyglot.t(`data.identity.birthDate`)} value={identitySection.data.userInfo.birthDate} />
                                  <SingleValue id={polyglot.t(`data.identity.birthPlace`)} value={identitySection.data.userInfo.birthPlace} />
                                  <ImageValue id={polyglot.t(`data.identity.image`)} value={identitySection.data.userInfo.image} />
                                </React.Fragment>
                            )}

                            </Section>
                          </DataCategory>
                        )}

                        {familyStatusSection && (
                          <DataCategory display={familyStatusSection} category='familyStatus' icon={(familyStatusCategory && familyStatusCategory.icon) || ''}>
                            <Section singleSection={true} status={familyStatusSection.result.status} title={polyglot.t(`data.familyStatus.title`)} source='aadefamilyStatus' >
                              {familyStatusSection.data && (
                                <React.Fragment>
                                  <SingleValue id={polyglot.t(`data.familyStatus.maritalStatusDesc`)} value={familyStatusSection.data.maritalStatusDesc} />
                                </React.Fragment>
                              )}
                            </Section>
                          </DataCategory>
                        )}

                        {incomeSection && (
                          <DataCategory display={incomeSection} category='income' icon={(incomeCategory && incomeCategory.icon) || ''}>
                            <Section singleSection={true} status={incomeSection.result.status} source='aadeIncome'>
                              {incomeSection.result.status.substr(0, 2) === '12' ? (
                                <React.Fragment>
                                  <SingleValue id={`${polyglot.t(`data.income.refYear`)}`} value={
                                    <React.Fragment>
                                      {incomeSection.data.refYear}{' '}
                                              (<DateValue value={incomeSection.data.releaseDate} inline />)
                                              </React.Fragment>
                                  } />
                                  <IncomeCategory>
                                    <MoneyValue id={polyglot.t(`data.income.wagesPensionsIncome`)} value={incomeSection.data.wagesPensionsIncome} />
                                    <MoneyValue id={polyglot.t(`data.income.maritimeIncome`)} value={incomeSection.data.maritimeIncome} />
                                  </IncomeCategory>
                                  <IncomeCategory>
                                    <MoneyValue id={polyglot.t(`data.income.farmingIncome`)} value={incomeSection.data.farmingIncome} />
                                    <MoneyValue id={polyglot.t(`data.income.damageFarmingCurr`)} value={incomeSection.data.damageFarmingCurr} />
                                    <MoneyValue id={polyglot.t(`data.income.grossFarming`)} value={incomeSection.data.grossFarming} />
                                  </IncomeCategory>
                                  <IncomeCategory>
                                    <MoneyValue id={polyglot.t(`data.income.businessIncome`)} value={incomeSection.data.businessIncome} />
                                    <MoneyValue id={polyglot.t(`data.income.damageBusinessCurr`)} value={incomeSection.data.damageBusinessCurr} />
                                    <MoneyValue id={polyglot.t(`data.income.grossBusiness`)} value={incomeSection.data.grossBusiness} />
                                  </IncomeCategory>
                                  <IncomeCategory>
                                    <MoneyValue id={polyglot.t(`data.income.investmentIncome`)} value={incomeSection.data.investmentIncome} />
                                    <MoneyValue id={polyglot.t(`data.income.rentalIncome`)} value={incomeSection.data.rentalIncome} />
                                    <MoneyValue id={polyglot.t(`data.income.capitalTransferValue`)} value={incomeSection.data.capitalTransferValue} />
                                  </IncomeCategory>
                                  <IncomeCategory>
                                    <MoneyValue id={polyglot.t(`data.income.taxableAmounts`)} value={incomeSection.data.taxableAmounts} />
                                    <MoneyValue id={polyglot.t(`data.income.unemploymentBenefits`)} value={incomeSection.data.unemploymentBenefits} />
                                  </IncomeCategory>
                                </React.Fragment>) : (
                                  incomeSection.data.status === 0 ? (
                                    <ErrorValue critical='true' error={polyglot.t('data.income.noClearedStatementFound')} />
                                  ) : (
                                      <ErrorValue critical='true' error={polyglot.t('data.income.errorOccurred')} />
                                    )
                                )}

                            </Section>
                          </DataCategory>
                        )}

                        {taxidInfoSection && (
                          <DataCategory display={taxidInfoSection} category='taxidInfo' icon={(taxidInfoCategory && taxidInfoCategory.icon) || ''}>
                            <Section singleSection={true} status={taxidInfoSection.result.status} title={polyglot.t(`data.taxidInfo.registryInfo`)} source='aadeTaxidInfo' >
                              {taxidInfoSection.data && (
                                <React.Fragment>
                                  <SingleValue id={polyglot.t(`data.taxidInfo.tin`)} value={taxidInfoSection.data.tin} />
                                  <SingleValue id={polyglot.t(`data.taxidInfo.doy`)} value={taxidInfoSection.data.doy} />
                                  <SingleValue id={polyglot.t(`data.taxidInfo.doyDescription`)} value={taxidInfoSection.data.doyDescr} />
                                </React.Fragment>
                              )}
                            </Section>
                          </DataCategory>
                        )}

                        {contactInfoSection && (
                          <DataCategory display={contactInfoSection} category='contactInfo' icon={(contactInfoCategory && contactInfoCategory.icon) || ''}>
                            {contactInfoSection.result && (
                              <Section template1201={<PartialContactUI/>} singleSection={true} status={contactInfoSection.result.status} source='ggps'>
                                <React.Fragment>
                                  {contactInfoSection.data.address && correctAddressData(contactInfoSection.data.address) ? (
                                      <SeparatedValue id={polyglot.t(`data.contactInfo.address`)} value={contactInfoSection.data.address.lines} />)
                                  :(
                                    <SingleValue id={polyglot.t(`data.contactInfo.address`)} value={'-'} />
                                  )}
                                  {contactInfoSection.data.address && correctAddressData(contactInfoSection.data.contactAddress) ? (
                                      <SeparatedValue id={polyglot.t(`data.contactInfo.contactAddress`)} value={contactInfoSection.data.contactAddress.lines} />)
                                  :(
                                    <SingleValue id={polyglot.t(`data.contactInfo.contactAddress`)} value={'-'} />
                                  )}
                                  <SingleValue id={polyglot.t(`data.contactInfo.telephone`)} value={contactInfoSection.data.telephone} />
                                  <SingleValue id={polyglot.t(`data.contactInfo.mobile`)} value={formatMobileNumber(contactInfoSection.data.mobile)} />
                                  <SingleValue id={polyglot.t(`data.contactInfo.email`)} value={contactInfoSection.data.email} />
                                </React.Fragment>
                                {contactInfoPartialData && (
                                    <PartialContactUI/>
                                )}
                              </Section>)}
                              
                          </DataCategory>
                        )}

                        {demographicsSection && (
                          <DataCategory display={demographicsSection} category='demographics' icon={(demographicsCategory && demographicsCategory.icon) || ''}>
                            <Section singleSection={true} status={demographicsSection.result.status} title={polyglot.t(`data.demographics.title`)} source='ypes' >
                              {demographicsSection.data && (
                                <React.Fragment>
                                  <SingleValue id={polyglot.t(`data.demographics.birthCountryName`)} value={demographicsSection.data.birthCountryName} />
                                  <SingleValue id={polyglot.t(`data.demographics.mainNationalityName`)} value={demographicsSection.data.mainNationalityName} />
                                </React.Fragment>
                              )}
                            </Section>
                          </DataCategory>
                        )}
                        
                        {privateEmployeeInfoSection && publicEmployeeInfoSection && selfEmployedInfoSection && unemploymentInfoSection && pensionerInfoSection && iekStudentInfoSection && (
                          <DataCategory display={privateEmployeeInfoSection && publicEmployeeInfoSection && selfEmployedInfoSection && unemploymentInfoSection && pensionerInfoSection && iekStudentInfoSection } category='professionalActivity' icon={(professionalActivityCategory && professionalActivityCategory.icon) || ''}>
                            <Section singleSection={false} status={privateEmployeeInfoSection.result.status} source='ergani' title={polyglot.t('data.professionalActivity.privateEmployeeInfo')}>
                              {privateEmployeeInfoSection.result.status.substr(0, 2) === '12' && (
                                <React.Fragment>
                                  {privateEmployeeInfoSection.data ? (privateEmployeeInfoSection.data.map((job, i) => (
                                    <React.Fragment key={i}>
                                      {(i !== 0) && (
                                        <hr />
                                      )}
                                      <h4>Εργοδότης</h4>
                                      <SingleValue id={polyglot.t('data.professionalActivity.merTaxId')} value={job.firm.tin} />
                                      <SingleValue id={polyglot.t('data.professionalActivity.merName')} value={job.firm.name} />
                                      <SeparatedValue id={polyglot.t('data.professionalActivity.jobAddress')} value={job.firm.main.address.lines} />
                                      <SingleValue id={polyglot.t('data.professionalActivity.activity')} value={`${job.firm.main.activityDescr} (${job.firm.main.activityCode})`} />
                                      <SingleValue id={polyglot.t('data.professionalActivity.website')} value={job.firm.website} />
                                      {job.firm.department && (
                                        <React.Fragment>
                                          <h4>{polyglot.t('data.professionalActivity.branch')}</h4>
                                          <SeparatedValue id={polyglot.t('data.professionalActivity.jobAddress')} value={job.firm.department.address.lines} />
                                          <SingleValue id={polyglot.t('data.professionalActivity.activity')} value={`${job.firm.department.activityDescr} (${job.firm.department.activityCode})`} />
                                        </React.Fragment>
                                      )}
                                      <h4>Εργασιακή Σχέση</h4>
                                      {/* <SingleValue id={polyglot.t('data.professionalActivity.specialization')} value={job.occupation.descr} /> */}
                                      <MapValue id={polyglot.t('data.professionalActivity.regime')} value={job.contractType}
                                        mapping={{
                                          "0": polyglot.t('data.professionalActivity.full').toUpperCase(),
                                          "1": polyglot.t('data.professionalActivity.partial').toUpperCase(),
                                          "2": polyglot.t('data.professionalActivity.shiftwork').toUpperCase()
                                        }} />
                                    </React.Fragment>
                                  ))) : (
                                      <ErrorValue critical='false' error={polyglot.t('data.noData')} />
                                    )}
                                </React.Fragment>)}
                            </Section>


                            <Section singleSection={false} status={publicEmployeeInfoSection.result.status} source='publicSector' title={polyglot.t('data.professionalActivity.publicEmployeeInfo')}>
                              {publicEmployeeInfoSection.result.status.substr(0, 2) === '12' && (
                                <React.Fragment>
                                  {publicEmployeeInfoSection.data ? (publicEmployeeInfoSection.data.map((job, i) => (
                                    <React.Fragment key={i}>
                                      {job.employmentPosition && (
                                        <React.Fragment>
                                          <h4>Οργανικός Φορέας</h4>
                                          <SingleValue id={polyglot.t('data.professionalActivity.public.vat')} value={job.employmentPosition.tin} />
                                          <SingleValue id={polyglot.t('data.professionalActivity.public.name')} value={job.employmentPosition.name} />
                                          <SeparatedValue id={polyglot.t('data.professionalActivity.public.address')} value={job.employmentPosition.address.lines} />
                                        </React.Fragment>
                                      )}

                                      {job.workPosition && (
                                        <React.Fragment>
                                          <h4>Φορέας Απασχόλησης</h4>
                                          <SingleValue id={polyglot.t('data.professionalActivity.public.vat')} value={job.workPosition.tin} />
                                          <SingleValue id={polyglot.t('data.professionalActivity.public.name')} value={job.workPosition.name} />
                                          <SeparatedValue id={polyglot.t('data.professionalActivity.public.address')} value={job.workPosition.address.lines} />
                                        </React.Fragment>
                                      )}

                                      <h4>Στοιχεία Θέσης</h4>
                                      <SingleValue id={polyglot.t('data.professionalActivity.public.type')} value={job.employmentType} />
                                      <SingleValue id={polyglot.t('data.professionalActivity.public.category')} value={job.occupation.categoryDescription}/>
                                      {/* <SingleValue id={polyglot.t('data.professionalActivity.public.sector')} value={job.occupation.sectorDescription}/> */}
                                      {/* <SingleValue id={polyglot.t('data.professionalActivity.public.specialty')} value={job.occupation.specialtyDescription} /> */}

                                      <hr />
                                    </React.Fragment>
                                  ))) : (
                                      <ErrorValue error={polyglot.t('data.noData')} />
                                    )}
                                </React.Fragment>
                              )}
                            </Section>

                            <Section singleSection={false} status={selfEmployedInfoSection.result.status} source='aadeSelfEmployed' title={polyglot.t('data.professionalActivity.selfEmployedInfo')}>
                              {selfEmployedInfoSection.result.status.substr(0, 2) === '12' && (
                                <React.Fragment>
                                  { selfEmployedInfoSection.data && selfEmployedInfoSection.data.main ? (
                                    <React.Fragment>
                                        <SingleValue id={ polyglot.t('data.professionalActivity.names') } value={ selfEmployedInfoSection.data.name }/>
                                        <SeparatedValue id={ polyglot.t('data.professionalActivity.firmBase') } value={ selfEmployedInfoSection.data.main.address.lines}/>
                                        <DateValue id={ polyglot.t('data.professionalActivity.startDate')} value={selfEmployedInfoSection.data.firmActStartDate}/>
                                        <SingleValue id={ polyglot.t('data.professionalActivity.primaryActivity') }
                                            value={printActivity(selfEmployedInfoSection.data.main.activities.find(act => act.activityType === "1"))}
                                          />
                                        <SingleValue id={ polyglot.t('data.professionalActivity.secondaryActivities') }
                                          value={ printSecondary(selfEmployedInfoSection.data.main.activities.filter(activity => activity.activityType !== "1"))}
                                          />
                                    </React.Fragment>) : (
                                        <ErrorValue critical='false' error={polyglot.t('data.noData')}/>
                                    )}

                                </React.Fragment>

                              )}
                            </Section>

                            <Section singleSection={false} status={unemploymentInfoSection.result.status} source='dypa' title={polyglot.t('data.professionalActivity.unemploymentInfo')}>
                              {unemploymentInfoSection.result.status.substr(0, 2) === '12' && (
                                <React.Fragment>
                                  {unemploymentInfoSection.data ? (
                                    <React.Fragment>
                                      <MapValue id={polyglot.t('data.professionalActivity.repidot')} value={unemploymentInfoSection.data.repidot}
                                        mapping={{
                                          "0": polyglot.t('data.professionalActivity.inactive').toUpperCase(),
                                          "1": polyglot.t('data.professionalActivity.active').toUpperCase(),
                                        }} />
                                      <SingleValue id={`${polyglot.t('data.professionalActivity.rmonthsepidot')} ${dateOneYearAgo}`} value={unemploymentInfoSection.data.rmonthsepidot} />
                                    </React.Fragment>) : (
                                    <ErrorValue critical='false' error={polyglot.t('data.noData')} />
                                  )}

                                </React.Fragment>

                              )}
                            </Section>
                           
                           
                            <Section singleSection={false} status={pensionerInfoSection.result.status} source='efka' title={polyglot.t('data.professionalActivity.pensionerInfo')}>
                              {pensionerInfoSection.result.status.substr(0, 2) === '12' && (
                                <React.Fragment>
                                  {pensionerInfoSection.data ? (
                                    <React.Fragment>
                                      <MapValue id={polyglot.t('data.professionalActivity.isPensioner')} value={pensionerInfoSection.data.isPensioner}
                                        mapping={{
                                          true: polyglot.t('data.professionalActivity.yes').toUpperCase(),
                                          false: polyglot.t('data.professionalActivity.no').toUpperCase(),
                                        }} />
                                    </React.Fragment>) : (
                                    <ErrorValue critical='false' error={polyglot.t('data.noData')} />
                                  )}

                                </React.Fragment>

                              )}
                            </Section>

                            <Section singleSection={false} status={academicInfoSection.result.status} source='edyte' title={polyglot.t('data.professionalActivity.academicInfo')}>
                              {academicInfoSection.result.status.substr(0, 2) === '12' && (
                                <React.Fragment>
                                  {academicInfoSection.data ? (
                                    <React.Fragment>
                                      <SeparatedValue id={polyglot.t(`data.professionalActivity.academicId`)} value={academicInfoSection.data.academicProperties} />
                                      {/* <ImageValue id={polyglot.t(`data.professionalActivity.image`)} value={academicInfoSection.data.image} /> */}
                                    </React.Fragment>) : (
                                    <ErrorValue critical='false' error={polyglot.t('data.noData')} />
                                  )}

                                </React.Fragment>

                              )}
                            </Section>

                            <Section singleSection={false} status={iekStudentInfoSection.result.status} source='minedu' title={polyglot.t('data.professionalActivity.iekStudentInfo')}>
                              {iekStudentInfoSection.result.status.substr(0, 2) === '12' && (
                                <React.Fragment>
                                  {iekStudentInfoSection.data ? (
                                    <React.Fragment>
                                      <MapValue id={polyglot.t('data.professionalActivity.isIekStudent')} value={iekStudentInfoSection.data.isActive}
                                        mapping={{
                                          true: polyglot.t('data.professionalActivity.yes').toUpperCase(),
                                          false: polyglot.t('data.professionalActivity.no').toUpperCase(),
                                        }} />
                                    </React.Fragment>) : (
                                    <ErrorValue critical='false' error={polyglot.t('data.noData')} />
                                  )}

                                </React.Fragment>

                              )}
                            </Section>
                          </DataCategory>
                        )}
                      </React.Fragment>
                    ) : 
                    (
                        <div className='no-data'>
                          <h1>{polyglot.t('data.noData')}</h1>
                        </div>
                    )
                  )}
                </div>
                <div className='note'>
                  <FontAwesomeIcon icon='info-circle' />
                  <span>{polyglot.t('data.noteFaq')} <a target="_blank" href='https://www.gsis.gr/egov-KYC/FAQs_KYC' rel="noopener noreferrer">{polyglot.t('info.faq')}</a>.</span>
                </div>
              </div>
            </React.Fragment>) 
          : (
            <React.Fragment>
              {/* if the error is caused because the session has expired, redirect to the home page */}
              { httpError === '403'  && (
                  <Redirect to='/' noThrow/>
                )
              }
              <React.Fragment>
                <Error refresh={refreshData} userError={errorCode === '1530'} />
              </React.Fragment>
            </React.Fragment>
          )
        )}
      </LanguageConsumer>
    </React.Fragment>
  )
}


export default (props) => (
  <Authentication next='mydata'>
    <DataContent report={props.report} />
  </Authentication>
)
