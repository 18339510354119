import React, { useContext, useEffect } from 'react'
import { AuthContext } from "./AuthContext";
import Loader from '../components/Loader'
import { Urls } from '../index';

export default(props) => {

    const authContext = useContext(AuthContext)
    const refresh = async () => {
        await authContext.refresh()
    }

    useEffect(() => {
        refresh()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(authContext.loading > 0) {
        return (<Loader/>)
    } else if(!authContext.loggedIn) {
        window.location = Urls.authUrl + "/logout";
    }

    return props.children

}
