import React from 'react' 


export default(props) => (
  <div id='Footer'>
    <div className='pull-left'>
      <div className='footerLinks'>
        <ul className='footerList'>
          <li><a target="_blank" href="https://www.gsis.gr/egov-KYC/FAQs_KYC" rel="noopener noreferrer">Συχνές Ερωτήσεις</a></li>
          <li><a target="_blank" href="https://www.gov.gr/info/politiki-aporritou" rel="noopener noreferrer">Πολιτική Απορρήτου</a></li>
          <li><a target="_blank" href="https://www.gsis.gr/sites/default/files/KYC/Termsofuse_KYC.pdf" rel="noopener noreferrer">Όροι Χρήσης</a></li>
          <li><a target="_blank" href="https://support.gov.gr/guide/index.jsp?type=govgr05" rel="noopener noreferrer">Επικοινωνία</a></li>
        </ul>
      </div>
    </div>
    <div className='pull-right'>
      <img id='ggps-logo' src='/ggps.png' alt='ggps.png'/>
      <img id='ministry-logo' src='/ministry.png' alt='ministry.png'/>
    </div>
  </div>
)